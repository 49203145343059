<template>
  <div>
    <LoadingOverlay :active="!slideshow" is-full-page :can-cancel="false" />
    <PreviewLesson
      v-if="slideshow.id"
      :id="slideshow.id"
      title=""
      :hide-outline="true"
      class="h-screen">
      <template #banner>
        <div
          class="flex justify-end px-10 py-5 text-white w-full absolute top-0 left-auto right-0 gap-4"
          :style="{ 'z-index': 999999999 }">
          <div v-if="currentLevel !== '0'" class="flex items-center relative">
            <img src="/src/assets/surveys/levelBadge.svg" alt="" />
            <div
              class="text-white font-anomaliaBold text-xl absolute top-[4px] left-[14px]">
              {{ currentLevel }}
            </div>
          </div>

          <div
            v-if="!currentSlide?.hideProgress"
            class="relative overflow-hidden h-[35px] w-[160px]">
            <div class="pl-5 z-10 absolute left-0 top-0 h-full w-full">
              <BaseProgress
                color="yellow"
                :progress="progress"
                label-position="inside"
                :label-progress="false">
                <div class="h-7"></div>
              </BaseProgress>
            </div>
            <div
              class="z-50 absolute top-0 h-[35px] w-[160px]"
              :style="{
                backgroundImage: `url('/src/assets/surveys/progress.svg')`,
                backgroundRepeat: 'no-repeat',
              }"></div>
          </div>

          <div
            v-if="!currentSlide?.hideProgress"
            class="font-anomaliaBold text-xl text-white">
            {{ Math.round(progress) }}%
          </div>
        </div>
      </template>
      <template #controls>
        <div
          class="flex items-center justify-center px-10 py-5 text-white w-full fixed bottom-0 xl:bottom-20 left-auto right-auto gap-0"
          :style="{ 'z-index': 999999999 }">
          <BaseButton v-if="hasPrevSlide" color="none" @click="handlePrev">
            <div class="relative">
              <BackButton />
            </div>
          </BaseButton>
          <BaseButton color="none" @click="handleNext">
            <div class="relative">
              <img
                :src="
                  currentSlide?.disabled
                    ? '/src/assets/surveys/mapping-button-disabled.svg'
                    : '/src/assets/surveys/mapping-button.svg'
                "
                alt=""/>
              <div
                class="absolute h-full font-anomaliaBold text-xl text-white top-0 w-full flex items-center justify-center text-center">
                {{ getButtonText }}
              </div>
            </div>
          </BaseButton>
        </div>
      </template>
    </PreviewLesson>
    <BaseModal v-if="isModalOpened" size="sm" @close="closeModal">
      <template #body>
        <p class="text-base leading-relaxed text-secondary-500">
          משהו השתבש, אנא חזור ונסה מאוחר יותר
        </p>
      </template>
      <template #footer>
        <div class="flex justify-between">
          <BaseButton color="none" @click="closeModal">הבנתי</BaseButton>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<script setup lang="ts">
import {
  BaseButton,
  BaseProgress,
  PreviewLesson,
  createPresentation,
  BaseModal,
} from '@nwire/amit-design-system';
import { computed, onMounted, ref } from 'vue';
import { find, get } from 'lodash';
import { useRoute } from 'vue-router';
// import { submitSurvey } from '/@/services/surveys';
// import { useAccountStore } from '/@/app/store/account';
import BackButton from '/@/assets/back-button.svg?component';
import LoadingOverlay from 'vue-loading-overlay';
import { fetchPages } from '/@/app/services/courses';

// interface IBlock {
//   id: string;
//   type: string;
// }

// const accountStore = useAccountStore();
const route = useRoute();
const pages = ref([]);
const progress = ref(0);
const localState: any = {};
const isModalOpened = ref(false);

function closeModal() {
  isModalOpened.value = false;
}
const getChoiceEnabled = state => {
  return state.length >= 1;
};
const getTextAreaEnabled = state => {
  return state[0]?.text?.length;
};
const getTextAreaState = () => {
  return [];
};

const handlers = {
  choice: {
    initialValue: () => [],
    enabled: getChoiceEnabled,
  },
  textarea: {
    initialValue: getTextAreaState,
    enabled: getTextAreaEnabled,
  },
};

// const canContinue = () => {
//   return true;
//   // let goNext = true;
//   // each(states, (state, index) => {
//   //   //get its name
//   //   const block = blocks.find(block => block.id === index);
//   //   const handler: Function = get(handlers, `${block.name}.enabled`, () => []);
//   //   if (handler) {
//   //     goNext = goNext && handler(state, block);
//   //   }
//   // });
//   // return goNext;
// };

const toState = nextSlide => {
  const state = nextSlide.state || {};
  nextSlide.blocks.forEach(block => {
    const handler: Function = get(
      handlers,
      `${block.name}.initialValue`,
      () => [],
    );
    if (!state[block.id]) state[block.id] = handler(block);
  });
  return state;
};

// const router = useRouter();
const slideshow = ref({
  id: '',
  lesson: 'א',
  description: '',
  sections: [],
});

const outline = ref([]);

const firstSlide = ref(); // user mentor persona | default
const history = ref([]);
const isPreview = ref(true);

const {
  currentSlide,
  onAction,
  onReady,
  onNext,
  onPrev,
  handlePrev,
  handleNext,
  hasPrevSlide,
  // onFinish,
} = createPresentation({
  slideshow,
  outline,
  history: history,
  isPreview,
});

const currentLevel = computed(() => {
  if (!currentSlide.value?.section) return '0';

  // @ts-ignore
  // FIXME: is it really needed??
  return find(
    get(slideshow.value, 'sections', []),
    section => section.id === currentSlide.value.section,
  ).level;
});
const getButtonText = computed(() => {
  if (!currentSlide.value) return;
  if (firstSlide.value.id === currentSlide.value.id) return 'בואו נתחיל!';
  if (
    currentSlide.value.id === get(pages.value[pages.value.length - 1], 'id', 0)
  )
    return 'סיום';

  return 'להמשיך';
});

onAction(({ context, event }) => {
  if (!context) return;

  if (event.block.type !== 'element')
    context.updateState(event.block.id, event.value);
  //
  // let { state } = context;
  // const contextState = state();
  // let disabled = !canContinue(context.blocks(), contextState);
  // context.setDisabled(disabled);
});

onPrev(async () => {
  if (!currentSlide.hideProgress)
    progress.value = Math.max(progress.value - 100 / pages.value.length, 0);
});
//TODO - use onSlide + use canContinue here instead
onReady(({ context }) => {
  context.setDisabled(false);
});
onNext(async context => {
  if (context.get()) {
    context.next({});
    return;
  }

  if (!currentSlide.hideProgress)
    progress.value = Math.min(progress.value + 100 / pages.value.length, 100);
  localState[currentSlide.value.id] = context.state();

  let nextSlide = context.sync({});
  context.setLoading(false);

  if (!nextSlide) return;

  let state = nextSlide.state;
  const nextSlideQuestion = nextSlide.blocks.find(
    block => block.type === 'question' || block.name === 'persona_selection',
  );

  if (nextSlideQuestion) {
    state = toState(nextSlide);
  }
  // const disabled = !canContinue(nextSlide.blocks, state);

  context.next({
    state,
    // disabled,
  });
});

// onFinish(async () => {
//   const blocks = pages.value.flatMap(page => get(page, 'blocks', []));
//   const records: any = [];
//   map(localState, page => {
//     for (const [key] of Object.entries(page)) {
//       const currentBlock: IBlock = blocks.find(
//         (block: IBlock) => block.id === key,
//       )!;

//       if (currentBlock && currentBlock.type === 'question') {
//         records.push({
//           question: currentBlock.id,
//           answer: page[currentBlock.id],
//         });
//       }
//     }
//   });

//   let data = {
//     student: accountStore.user?.id,
//     questionnaire: slideshow.value.id,
//     survey: route.params.survey,
//     records,
//   };
//   const { onSuccess, onError } = submitSurvey(data);

//   onSuccess(() => goToStudentDashboard());
//   onError(() => (isModalOpened.value = true));
// });

const fetchContent = () => {
  const { onSuccess } = fetchPages(
    route.params.survey as string,
    route.params.questionnaire as string,
  );
  onSuccess(({ data: questionnaire }) => {
    const style = document.createElement('style');
    style.innerHTML = questionnaire.display.customCss;
    document.head.appendChild(style);

    slideshow.value = questionnaire;
    firstSlide.value = get(questionnaire, 'sections.0.pages.0', null);
    pages.value = get(questionnaire, 'sections', []).flatMap(
      section => section.pages,
    );
    progress.value = Math.floor(
      100 / (pages.value.length - history.value.length),
    );
  });
};

onMounted(() => {
  fetchContent();
});
</script>
<style></style>
